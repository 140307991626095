import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',// 公司首頁
    name: 'home',
    component: () => import( '../views/Company-homepage.vue')
    // component: () => import( '../views/Com')
    // component: Company-homepage 
  },
  {
    path: '/About-us', //关于我们
    name: 'about',
    component: () => import( '../views/About-us.vue')
  },
  {
    path: '/Service-item',//服务项目    
    name: 'Service-item',
    component: () => import( '../views/Service-item.vue')
  },
  {
    path: '/Project-bidding',//项目招标   
    name: 'Project-bidding',
    component: () => import( '../views/Project-bidding.vue')
  },
  {
    path: '/Eagle-Alliance',// 飞鹰联盟  
    name: 'Eagle-Alliance',
    component: () => import( '../views/Eagle-Alliance.vue')
  },
  {
    path: '/Industry-dynamics',//行业动态 
    name: 'Industry-dynamics',
    component: () => import( '../views/Industry-dynamics.vue')
  },
  {
    path: '/Contact-customer-service',//联系客服
    name: 'service',
    component: () => import( '../views/Contact-customer-service.vue')
  },
  {
    path: '/Legal-service',// 法律服务
    name: 'Legal-service',
    component: () => import( '../views/Legal-service.vue')
  },
  {
    path: '/Pilot-life',//飞行员生活 
    name: 'Pilot-life',
    component: () => import( '../views/Pilot-life.vue')
  },
  {
    path: '/Health-management',//健康管理  
    name: 'Health-management',
    component: () => import( '../views/Health-management.vue')
  },
  {
    path: '/Risk-management',//风险管理      
    name: 'Risk-management',
    component: () => import( '../views/Risk-management.vue')
  },
  {
    path: '/details',//详情      
    name: 'details',
    component: () => import( '../views/details.vue')
  }
  

]

const router = new VueRouter({
  routes
})

export default router
