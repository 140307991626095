<template>
  <!-- 尾部組件 -->

  <div class="foot mt100">
    <div class="foot-top df-jcsb-aic">
      <!-- 1 -->
      <div>
        <div class="foot-span">
          <img class="foot-img" src="../assets/img/name.png" alt="">
          <p>公司名称:</p>
          <span>陕西霁虹信息科技有限公司(飞行员专属服务平台)</span>
        </div>
        <div class="foot-span mt50">
          <img class="foot-img" src="../assets/img/work.png" alt="">
          <p>公司电话:</p>
          <span>400-0357-608 <br>18652917366 </span>
        </div>
      </div>
      <!-- 2 -->
      <div>
        <div class="foot-span">
          <img class="foot-img" src="../assets/img/adress.png" alt="">
          <p>公司地址:</p>
          <span>西安市浐灞生态区浐灞二路中铁青秀城华美达酒店1304 </span>
        </div>
        <div class="foot-span mt50">
          <img class="foot-img" src="../assets/img/emear.png" alt="">
          <p>公司邮箱:</p>
          <span>jhpilot2016@163.com</span>
        </div>
      </div>
      <!-- 3 -->
      <div class="df-jcsa-aic">
        <div class="df-fdc-aic">
          <img class="foot-vximg" src="../assets/img/gongzhonghao.png" alt="">
          <p class="mt15">关注公众号</p>
        </div>
        <div class="df-fdc-aic ml50 ">
          <img class="foot-vximg" src="../assets/img/weixin.png" alt="">
          <p class="mt15">微信扫码咨询 </p>
        </div>
      </div>
    </div>
    <span style="display: block;line-height: 50px;"> * 本平台仅提供技术支持、信息服务、广告展示、以及咨询服务，所有保险产品的销售、理赔等服务均有美联盛航保险代理公司提供。 </span>
    <div class="foot-bottom">
      <div class="foot-bottom-content ">
        <span style="display: block;line-height: 30px;">
          陕西霁虹信息科技有限公司版权所有 Copyright2022-2025
        </span>
        
        <span style="line-height: 30px;display:flex;align-items: center;justify-content: center;">
          <img style="width: 16px;height: 16px;" src="../assets/img/jh-bah.png" alt="">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?recordcode=61019702000445" style="text-decoration:none;color: #ccc;">&nbsp;陕公网安备61019702000445号</a>
        </span>
        <span style="display: block;line-height: 30px;display:flex;align-items: center;justify-content: center;">
          飞行员专属服务平台 备案号 :
          <a href="https://beian.miit.gov.cn" style="text-decoration:none;color: #ccc;">&nbsp;陕ICP备16017293号</a>
          
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="less">
.foot {
  width: 100%;
  // height: 350px;
  background-color: #1b1b1b;
  color: #ccc;
  text-align: center;
}

.foot-top {
  width: 1200px;
  margin: 0 auto;
  height: 250px;
  color: #ccc;
}

.foot-bottom {
  width: 100%;
  height: 100px;
  background-color: #000;
  color: #ccc;
}

.foot-bottom-content {
  width: 1200px;
  margin: 0 auto;
  height: 100px;
  background-color: #000;
  color: #ccc;
}

.foot-img {
  width: 50px;
  height: 50px;
}

.foot-vximg {
  width: 100px;
  height: 100px;
}

.foot-span {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  width: 290px;

  p {
    width: 120px;
  }

  span {
    width: 200px;
  }
}</style>
