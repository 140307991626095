<template>
  <!-- 頭部組件 -->

  <div class="web-heard">
    <div style="width: 50px;"></div>
    <img style="height: 60px;" src="../assets/img/logo-heard.png" alt="">
    <div class="nav-heard">
      <!-- <div :class="[activeIndex==1 ? 'activeClass' : 'errorClass']" @click="jump(1)">公司首页</div>
      <div :class="[activeIndex==2 ? 'activeClass' : 'errorClass']" @click="jump(2)">关于我们</div>
      <div :class="[activeIndex==3 ? 'activeClass' : 'errorClass']"  @click="jump(3)">服务项目</div>
      <ul style="margin-top: 50px;" class="ul"  id="hoverdiv">
        <li :class="[activeIndex==4 ? 'activeClass' : '']" id="test1" @click="jump(4)">法律服务</li>
        <li :class="[activeIndex==5 ? 'activeClass' : '']" id="test2" @click="jump(5)">飞行员生活</li>
        <li :class="[activeIndex==6 ? 'activeClass' : '']" id="test3" @click="jump(6)">健康管理</li>
        <li :class="[activeIndex==7 ? 'activeClass' : '']" id="test4" @click="jump(7)">风险管理</li>
      </ul>
      <div :class="[activeIndex==8 ? 'activeClass' : 'errorClass']" @click="jump(8)">项目招标</div>
      <div :class="[activeIndex==9 ? 'activeClass' : 'errorClass']" @click="jump(9)">飞鹰联盟</div>
      <div :class="[activeIndex==10 ? 'activeClass' : 'errorClass']" @click="jump(10)">行业动态</div>
      <div :class="[activeIndex==11? 'activeClass' : 'errorClass']" @click="jump(11)">联系客服</div> -->
      <el-menu :default-active="this.$router.path" :router="true" class="el-menu-demo" mode="horizontal" popper-append-to-body="true" 
        style="width:700px;" @select="handleSelect">
        <el-menu-item index='/'>公司首页</el-menu-item>
        <el-menu-item index='/About-us'>关于我们</el-menu-item>

        <el-submenu style="width: 80px !important;" index='/Service-item'>
          <template slot="title">服务项目</template>
          <el-menu-item  index='/Legal-service'>法律服务</el-menu-item>
          <el-menu-item index='/Pilot-life'>飞行员生活</el-menu-item>
          <el-menu-item index='/Health-management'>健康管理</el-menu-item>
          <el-menu-item index='/Risk-management'>风险管理</el-menu-item>
        </el-submenu>
        <el-menu-item index='/Project-bidding'>项目招标</el-menu-item>
        <el-menu-item index='/Eagle-Alliance'>飞鹰联盟</el-menu-item>
        <el-menu-item index='/Industry-dynamics'>行业动态</el-menu-item>
        <el-menu-item index='/Contact-customer-service'>联系客服</el-menu-item>
      </el-menu>
    </div>
    <div style="display: flex;align-items: center;">
      <img style="height: 24px;" src="../assets/img/phone.png" alt="">
      <img style="height: 24px;" src="../assets/img/number.png" alt="">
    </div>
    <div style="width: 50px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 1,
      show: true,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    jump(v) {
      this.activeIndex = v
      var t1 = document.getElementById('hoverdiv');
      t1.style.display = 'none';// 以块级样式显示
      if (v == 1) {
        this.$router.push('/')
      }
      if (v == 2) {
        this.$router.push('/About-us')
      }
      if (v == 3) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Service-item')
      }
      if (v == 4) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Legal-service')
      }
      if (v == 5) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Pilot-life')
      }
      if (v == 6) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Health-management')
      }
      if (v == 7) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Risk-management')
      }
      if (v == 8) {
        this.$router.push('/Project-bidding')
      }
      if (v == 9) {
        this.$router.push('/Eagle-Alliance')
      }
      if (v == 10) {
        this.$router.push('/Industry-dynamics')
      }
      if (v == 11) {
        this.$router.push('/Contact-customer-service')
      }

    }
  }
}
</script>
<style lang="less">
.web-heard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
}

.nav-heard {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: space-around;
  position: relative;
}

.ul {
  display: none;
  position: absolute;
  top: 30px;
  left: 374px;
  background-color: #fff;
  width: 100px;
}

.ul>li {
  // display: none;
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: #fff;
}

//  .ul>li:hover{
//   display: block;
//    width: 100px;
//   height: 80px;
//   line-height: 80px;
//   text-align: center;
//   background-color: red;
// }
.errorClass {
  // background-color: #275eab;
  width: 170px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.activeClass {
  color: #275eab;
  border-bottom: 2px solid #275eab;
  width: 170px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

// nav:hover .active {
//   display: block !important;
//   // background-color: red;
//   position: absolute;
//   top: 60px;
//   left: 200px;
//   display: flex;
//   flex-direction: column;
//   width: 100px;
//   text-align: center;
// }
.el-menu-demo{
  width: 1000px !important;
  display: flex !important;
  justify-content: space-between !important;
}

  

/* menu子菜单区域 */
.el-menu--popup {
  /* 菜单偏上一点 */
  // margin-top: 18px !important;
  // border-radius: 6px !important;
  // background-image: linear-gradient(0deg, #eafefc 0%, #3f9cfc 100%);
  // padding: 10px 0px !important;
  // width: 80px !important;

  .el-menu-item {
    color: #333333 !important;
    background-color: transparent !important;
    margin: 5px 10px;
    border-radius: 4px;
    transition-duration: 0s;
    // width: 80px !important;
  }
  .el-menu-item:hover {
    color: #3f9cfc !important;
    background-color: #e9faf8 !important;
    filter: drop-shadow(0px 1px 0px #3f9cfc);
    background-color: #ffffff;
  }
  // .el-menu-item.is-active {
  //   color: #3f9cfc !important;
  //   background-color: #e9faf8 !important;
  //   filter: drop-shadow(0px 1px 0px #3f9cfc);
  //   background-color: #ffffff;
  // }
}

</style>