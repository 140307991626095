<template>
<div>
  <!-- 頭部組件 -->
  <HelloWorld/>

  <!-- 内容 -->
  <div class="content">
    <router-view/> <!-- 漏油出口 -->
  </div>

  <!-- 尾部組件 -->
  <!-- <Foot/> -->
   <Foot/>
  <div class="footbox df">
    <img class="img1" src="./assets/img/dnzx.png" alt="">
    <img class="img2" src="./assets/img/sj1.png" alt="">
    <!-- <img class="img1" src="./assets/img/dnzx.png" alt=""> -->
      
  </div>

  </div>
</template>

<script>
import '@/assets/css/global.css' /*引入公共样式*/
import HelloWorld from '@/components/HelloWorld.vue'
import Foot from '@/components/Foot-bottom.vue'
export default {
  components: {
    HelloWorld,
    Foot,
  }
}
</script>
<style lang="less">
.footbox{
  position: fixed;
  top:50%;
  right: 0px;
  margin-top: -75px;
  width: 50px;
  height: 150px;
  // background-color: red;
}
.img1{
  width: 50px;
  height: 150px;
}
.img2{
  width: 150px;
  height: 150px;
}

	
	// {
	// 	list-style: none;
	// 	width: 160px;
	// 	height: 240px;
	// 	background-color: white;
	// 	float:left;
	// 	margin-left:40px;
	// 	border-radius: 10px;
	// }
	
	.footbox:hover .img2{
		cursor:pointer;
		/* transform属性 */
		transform:translate(-200px,0px);
    // transform: translate(40px);
		/* 第一个参数指定X轴的位移量,必填, 第二个参数指定Y轴的位移量,不必填 默认0*/
	}


</style>
