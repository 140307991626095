import { render, staticRenderFns } from "./Foot-bottom.vue?vue&type=template&id=03a6e941&"
import script from "./Foot-bottom.vue?vue&type=script&lang=js&"
export * from "./Foot-bottom.vue?vue&type=script&lang=js&"
import style0 from "./Foot-bottom.vue?vue&type=style&index=0&id=03a6e941&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports